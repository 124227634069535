import { ReactElement, useEffect, useReducer, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import {
    Badge,
    Input,
    InputGroup,
    Label,
} from '@joshfarrant/tailwind-ui-react';
import {
    DeviceMobileIcon,
    KeyIcon,
    LightningBoltIcon,
    PhoneIncomingIcon,
    ServerIcon,
} from '@heroicons/react/solid';
import {
    ExclamationCircleIcon,
    ExclamationIcon,
} from '@heroicons/react/outline';
import equal from 'fast-deep-equal';
import { useToasts } from 'react-toast-notifications';

import {
    ActionPanel,
    Alert,
    DangerButton,
    GenericError,
    InboundCalls,
    Layout,
    MobileConfiguration,
    PendingChanges,
    PrimaryButton,
    ScrollIntoView,
    SipRegistration,
    Tab,
    TabView,
    UnsupportedSim,
    WhiteButton,
} from '../..';
import {
    formatIccid,
    getStatusBadgeColor,
    isErrorResponse,
} from '../../../utils';
import {
    initialState,
    reducer,
    resetRegistrationConfigChanges,
    resetSimConfigChanges,
    resetSimDetailChanges,
    resetSimInboundConfigChanges,
    resetErrors,
    setRegistrationConfigChanges,
    setSimConfigChanges,
    setSimDetailChanges,
    setSimInboundConfigChanges,
    setErrors,
} from './allocated-sim.reducer';
import { useMutations, useQueries } from './allocated-sim.hook';
import { TAllocatedSimProps } from './allocated-sim.types';
import { simTypes } from 'src/constants';

const heading = 'Your SIMs';

export const AllocatedSim = ({
    selectedTabId,
    simType,
}: TAllocatedSimProps): ReactElement => {
    const [suspendModalOpen, setSuspendModalOpen] = useState(false);
    const [terminateModalOpen, setTerminateModalOpen] = useState(false);
    const [pukModalOpen, setPukModalOpen] = useState(false);
    const [terminateSimConfirm, setTerminateSimConfirm] = useState('');
    const suspendCancelButtonRef = useRef(null);
    const terminateCancelButtonRef = useRef(null);
    const pukDoneButtonRef = useRef(null);
    const [state, dispatch] = useReducer(reducer, initialState);
    const { addToast } = useToasts();

    const router = useRouter();
    const { spid, iccid } = router.query;

    const simTypeText = simType == simTypes.eSims ? 'E-SIM' : 'SIM';
    const {
        simDetailQuery,
        simConfigQuery,
        simRegistrationStatusQuery,
        simRegistrationConfigQuery,
        simInboundConfigQuery,
    } = useQueries({ spid, iccid, simType });

    const simDetailResponse = simDetailQuery.data;
    const simConfigResponse = simConfigQuery.data;
    const simRegistrationStatusResponse = simRegistrationStatusQuery.data;
    const simRegistrationConfigResponse = simRegistrationConfigQuery.data;
    const simInboundConfigResponse = simInboundConfigQuery.data;

    const {
        simConfigMutation,
        simDetailMutation,
        simRegistrationConfigMutation,
        simInboundConfigMutation,
        suspendSimMutation,
        unsuspendSimMutation,
        terminateSimMutation,
    } = useMutations();

    const resetChanges = () => {
        dispatch(resetSimConfigChanges());
        dispatch(resetSimDetailChanges());
        dispatch(resetRegistrationConfigChanges());
        dispatch(resetSimInboundConfigChanges());
    };

    useEffect(() => {
        resetChanges();
    }, [iccid]);

    const pageTitle = `Editing ${iccid} [${selectedTabId}] : ApaloSIM`;

    if (
        !simDetailResponse ||
        !simConfigResponse ||
        !simRegistrationStatusResponse ||
        !simRegistrationConfigResponse ||
        !simInboundConfigResponse
    ) {
        return <Layout pageTitle={pageTitle} heading={heading} isLoading />;
    }

    if (
        !isErrorResponse(simDetailResponse) &&
        simDetailResponse.data[0].type !== 'sip'
    ) {
        return (
            <Layout pageTitle={pageTitle} heading={heading}>
                <ActionPanel
                    heading={formatIccid(simDetailResponse.data[0].iccid)}
                    subHeading={simDetailResponse.data[0].msisdn}
                />
                <UnsupportedSim />
            </Layout>
        );
    }

    if (
        isErrorResponse(simDetailResponse) ||
        isErrorResponse(simConfigResponse) ||
        isErrorResponse(simRegistrationStatusResponse) ||
        isErrorResponse(simRegistrationConfigResponse) ||
        isErrorResponse(simInboundConfigResponse)
    ) {
        return (
            <Layout pageTitle={pageTitle} heading={heading}>
                <GenericError />
            </Layout>
        );
    }

    const simDetail = simDetailResponse.data[0];
    const simConfig = simConfigResponse.data[0];
    const simRegistrationStatus = simRegistrationStatusResponse.data[0];
    const simRegistrationConfig = simRegistrationConfigResponse.data[0];
    const simInboundConfig = simInboundConfigResponse.data[0];

    const modifiedSimConfig = {
        ...simConfig,
        ...state.simConfigChanges,
    };

    const modifiedSimDetail = {
        ...simDetail,
        ...state.simDetailChanges,
    };

    const modifiedRegistrationConfig = {
        ...simRegistrationConfig,
        ...state.registrationConfigChanges,
    };

    const modifiedInboundConfig = {
        ...simInboundConfig,
        ...state.simInboundConfigChanges,
    };

    const { errors, isDirty } = state.meta;

    const hasPendingChanges = simConfig.changes_pending;

    const saveChanges = () => {
        dispatch(resetErrors());

        const hasConfigChanges = !equal(simConfig, modifiedSimConfig);
        const hasDetailChanges = !equal(simDetail, modifiedSimDetail);
        const hasRegistrationConfigChanges = !equal(
            simRegistrationConfig,
            modifiedRegistrationConfig,
        );
        const hasInboundConfigChanges = !equal(
            simInboundConfig,
            modifiedInboundConfig,
        );

        const handlers = {
            onError: (errors: unknown) => {
                if (Array.isArray(errors)) {
                    dispatch(setErrors(errors));
                    return;
                }

                dispatch(
                    setErrors([
                        'Something went wrong. Please try again or raise a support ticket if this continues.',
                    ]),
                );
            },
            onSuccess: async () => {
                addToast(null, {
                    heading: `${simTypeText} Updated Successfully`,
                    appearance: 'success',
                });
                dispatch(resetErrors());

                await Promise.all([
                    simDetailQuery.refetch(),
                    simConfigQuery.refetch(),
                    simRegistrationStatusQuery.refetch(),
                    simRegistrationConfigQuery.refetch(),
                    simInboundConfigQuery.refetch(),
                ]);

                resetChanges();
            },
        };

        if (
            !hasConfigChanges &&
            !hasDetailChanges &&
            !hasRegistrationConfigChanges &&
            !hasInboundConfigChanges
        ) {
            resetChanges();
            return;
        }

        if (hasConfigChanges) {
            simConfigMutation.mutate(
                {
                    updatedSimConfig: { ...state.simConfigChanges },
                    spid,
                    iccid,
                    simType,
                },
                handlers,
            );
        }

        if (hasDetailChanges) {
            simDetailMutation.mutate(
                {
                    updatedSimDetail: { ...state.simDetailChanges },
                    spid,
                    iccid,
                    simType,
                },
                handlers,
            );
        }

        if (hasRegistrationConfigChanges) {
            simRegistrationConfigMutation.mutate(
                {
                    updatedSimRegistrationConfig: {
                        ...modifiedRegistrationConfig,
                    },
                    spid,
                    iccid,
                    simType,
                },
                handlers,
            );
        }

        if (hasInboundConfigChanges) {
            simInboundConfigMutation.mutate(
                {
                    updatedSimInboundConfig: {
                        ...modifiedInboundConfig,
                    },
                    spid,
                    iccid,
                    simType,
                },
                handlers,
            );
        }
    };

    const hasErrors = errors.length > 0;

    const buttonsDisabled =
        simConfigMutation.isLoading ||
        simDetailMutation.isLoading ||
        simRegistrationConfigMutation.isLoading ||
        simInboundConfigMutation.isLoading ||
        !isDirty;

    const isSuspended = simDetail.status === 'suspended';

    const saveAndCancelButtons = (
        <div className="flex justify-end">
            <WhiteButton
                className="mr-4"
                onClick={() => {
                    resetChanges();
                }}
                disabled={buttonsDisabled}
            >
                Cancel
            </WhiteButton>
            <PrimaryButton
                disabled={buttonsDisabled}
                onClick={() => {
                    saveChanges();
                }}
            >
                Save
            </PrimaryButton>
        </div>
    );

    return (
        <Layout pageTitle={pageTitle} heading={heading}>
            <ActionPanel
                heading={formatIccid(simDetail.iccid)}
                subHeading={simDetail.msisdn}
                HeadingItems={() => (
                    <Badge
                        className="mt-2 sm:mt-0 sm:ml-4"
                        color={getStatusBadgeColor(simDetail.status)}
                        shape="rounded"
                    >
                        {simDetail.status.toUpperCase()}
                    </Badge>
                )}
                Buttons={() => (
                    <>
                        <PrimaryButton
                            leadingIcon="Key"
                            className="mr-4"
                            onClick={() => {
                                setPukModalOpen(true);
                            }}
                        >
                            {simTypeText} PUK
                        </PrimaryButton>
                        {isSuspended ? (
                            <>
                                <WhiteButton
                                    leadingIcon="LightningBolt"
                                    className="mr-4"
                                    onClick={() => {
                                        setSuspendModalOpen(true);
                                    }}
                                >
                                    Unsuspend
                                </WhiteButton>
                                <DangerButton
                                    leadingIcon="Exclamation"
                                    onClick={() => {
                                        setTerminateModalOpen(true);
                                    }}
                                >
                                    Terminate
                                </DangerButton>
                            </>
                        ) : (
                            <DangerButton
                                leadingIcon="Ban"
                                onClick={() => {
                                    setSuspendModalOpen(true);
                                }}
                            >
                                Suspend
                            </DangerButton>
                        )}
                    </>
                )}
            />
            {hasPendingChanges && <PendingChanges simTypeText={simTypeText} />}
            {hasErrors && (
                <ScrollIntoView className="bg-red-50 shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:p-6 flex flex-col items-start">
                        <div className="mb-2 flex flex-row items-center">
                            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                                <ExclamationCircleIcon
                                    className="h-6 w-6 text-red-600"
                                    aria-hidden="true"
                                />
                            </div>
                            <div className="ml-1 text-lg leading-6 font-medium text-red-800">
                                Error Modifying {simTypeText}
                            </div>
                        </div>
                        <div className="px-8 pb-3 flex flex-col">
                            <ul className="list-disc list-inside">
                                {errors.map(error => (
                                    <li key={error}>{error}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </ScrollIntoView>
            )}
            <TabView
                tabs={[
                    { id: 'mobile', heading: 'Mobile', Icon: DeviceMobileIcon },
                    {
                        id: 'sip',
                        heading: 'SIP Registration',
                        Icon: ServerIcon,
                    },
                    {
                        id: 'inbound',
                        heading: 'Inbound Calls',
                        Icon: PhoneIncomingIcon,
                    },
                ]}
                simType={simType}
                selectedTabId={selectedTabId}
                setQueryParam
            >
                <Tab id="mobile">
                    <MobileConfiguration
                        simConfig={modifiedSimConfig}
                        simDetail={modifiedSimDetail}
                        onConfigChange={config => {
                            dispatch(setSimConfigChanges(config));
                        }}
                        onDetailChange={detail => {
                            dispatch(setSimDetailChanges(detail));
                        }}
                        disabled={hasPendingChanges}
                    />
                    {saveAndCancelButtons}
                </Tab>
                <Tab id="sip">
                    <SipRegistration
                        registrationStatus={simRegistrationStatus}
                        registrationConfig={modifiedRegistrationConfig}
                        persistedRegistrationConfig={simRegistrationConfig}
                        onConfigChange={config => {
                            dispatch(setRegistrationConfigChanges(config));
                        }}
                    />
                    {saveAndCancelButtons}
                </Tab>
                <Tab id="inbound">
                    <InboundCalls
                        inboundConfig={modifiedInboundConfig}
                        onConfigChange={config => {
                            dispatch(setSimInboundConfigChanges(config));
                        }}
                    />
                    {saveAndCancelButtons}
                </Tab>
            </TabView>
            <Alert
                heading={
                    isSuspended
                        ? `Unsuspend ${simTypeText}?`
                        : `Suspend ${simTypeText}?`
                }
                onClose={() => {
                    setSuspendModalOpen(false);
                }}
                show={suspendModalOpen}
                initialFocus={suspendCancelButtonRef}
                Buttons={() => (
                    <div className="flex justify-end">
                        <WhiteButton
                            className="mr-4"
                            onClick={() => {
                                setSuspendModalOpen(false);
                            }}
                            ref={suspendCancelButtonRef}
                        >
                            Cancel
                        </WhiteButton>
                        {isSuspended ? (
                            <PrimaryButton
                                onClick={() => {
                                    unsuspendSimMutation.mutate(
                                        { spid, iccid, simType },
                                        {
                                            onSettled: () => {
                                                setSuspendModalOpen(false);
                                            },
                                            onError: (errors: unknown) => {
                                                if (Array.isArray(errors)) {
                                                    dispatch(setErrors(errors));
                                                    return;
                                                }

                                                dispatch(
                                                    setErrors([
                                                        `Something went wrong when unsuspending this ${simTypeText}. Please try again or raise a support ticket if this continues.`,
                                                    ]),
                                                );
                                            },
                                        },
                                    );
                                }}
                            >
                                Unsuspend
                            </PrimaryButton>
                        ) : (
                            <DangerButton
                                onClick={() => {
                                    suspendSimMutation.mutate(
                                        { spid, iccid, simType },
                                        {
                                            onSettled: () => {
                                                setSuspendModalOpen(false);
                                            },
                                            onError: (errors: unknown) => {
                                                if (Array.isArray(errors)) {
                                                    dispatch(setErrors(errors));
                                                    return;
                                                }

                                                dispatch(
                                                    setErrors([
                                                        `Something went wrong when suspending this ${simTypeText}. Please try again or raise a support ticket if this continues.`,
                                                    ]),
                                                );
                                            },
                                        },
                                    );
                                }}
                            >
                                {isSuspended ? 'Unsuspend' : 'Suspend'}
                            </DangerButton>
                        )}
                    </div>
                )}
                Icon={() =>
                    isSuspended ? (
                        <LightningBoltIcon
                            className="h-6 w-6 text-primary-600"
                            aria-hidden="true"
                        />
                    ) : (
                        <ExclamationIcon
                            className="h-6 w-6 text-red-600"
                            aria-hidden="true"
                        />
                    )
                }
                iconBackgroundClassName={
                    isSuspended ? 'bg-primary-100' : 'bg-red-100'
                }
            >
                <p className="text-sm text-gray-500">
                    Are you sure you want to{' '}
                    {isSuspended ? 'unsuspend' : 'suspend'} this {simTypeText}?
                </p>
            </Alert>
            <Alert
                heading={`Terminate ${simTypeText}?`}
                onClose={() => {
                    setTerminateSimConfirm('');
                    setTerminateModalOpen(false);
                }}
                show={terminateModalOpen}
                initialFocus={terminateCancelButtonRef}
                Buttons={() => (
                    <div className="flex justify-end">
                        <WhiteButton
                            className="mr-4"
                            onClick={() => {
                                setTerminateSimConfirm('');
                                setTerminateModalOpen(false);
                            }}
                            ref={terminateCancelButtonRef}
                        >
                            Cancel
                        </WhiteButton>
                        <DangerButton
                            disabled={terminateSimConfirm !== simDetail.msisdn}
                            onClick={() => {
                                terminateSimMutation.mutate(
                                    { spid, iccid, simType },
                                    {
                                        onSettled: () => {
                                            setTerminateSimConfirm('');
                                            setTerminateModalOpen(false);
                                        },
                                        onError: (errors: unknown) => {
                                            if (Array.isArray(errors)) {
                                                dispatch(setErrors(errors));
                                                return;
                                            }
                                            dispatch(
                                                setErrors([
                                                    `Something went wrong when terminating this ${simTypeText}. Please try again or raise a support ticket if this continues.`,
                                                ]),
                                            );
                                        },
                                    },
                                );
                            }}
                        >
                            Terminate
                        </DangerButton>
                    </div>
                )}
                Icon={() => (
                    <ExclamationIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                    />
                )}
                iconBackgroundClassName="bg-red-100"
            >
                <p className="text-sm text-gray-500 mb-4">
                    Are you sure you want to terminate this {simTypeText}?
                    <br />
                    This will render the {simTypeText} card{' '}
                    <strong>permanently unusable</strong>.
                </p>

                <p className="text-sm text-gray-500 mb-4">
                    Enter the {simTypeText}&apos;s MSISDN{' '}
                    <Badge className="font-mono" color="danger" shape="rounded">
                        {simDetail.msisdn}
                    </Badge>{' '}
                    below to confirm.
                </p>

                <InputGroup
                    className="mb-4"
                    id="label"
                    renderInput={inputProps => (
                        <Input
                            {...inputProps}
                            value={terminateSimConfirm}
                            onChange={e => {
                                setTerminateSimConfirm(e.target.value);
                            }}
                        />
                    )}
                    renderLabel={labelProps => (
                        <Label {...labelProps} hidden>
                            Confirm msisdn
                        </Label>
                    )}
                />
            </Alert>
            <Alert
                heading={`${simTypeText} PUK`}
                onClose={() => {
                    setPukModalOpen(false);
                }}
                show={pukModalOpen}
                initialFocus={pukDoneButtonRef}
                Buttons={() => (
                    <div className="flex justify-end">
                        <PrimaryButton
                            onClick={() => {
                                setPukModalOpen(false);
                            }}
                            ref={pukDoneButtonRef}
                        >
                            Done
                        </PrimaryButton>
                    </div>
                )}
                Icon={() => (
                    <KeyIcon
                        className="h-6 w-6 text-primary-600"
                        aria-hidden="true"
                    />
                )}
                iconBackgroundClassName="bg-primary-100"
            >
                <dl className="mt-5">
                    <div className="px-4 py-5 grid grid-cols-3 gap-4 sm:px-0 md:py-3 lg:py-2">
                        <dt className="text-sm font-medium text-gray-500 col-span-1">
                            PUK 1
                        </dt>
                        <dd className="text-sm text-gray-900 col-span-2 text-left">
                            {simDetail.puk1}
                        </dd>
                    </div>
                    <div className="bg-white px-4 py-5 grid grid-cols-3 gap-4 sm:px-0 md:py-3 lg:py-2">
                        <dt className="text-sm font-medium text-gray-500 col-span-1">
                            PUK 2
                        </dt>
                        <dd className="text-sm text-gray-900 col-span-2 text-left">
                            {simDetail.puk2}
                        </dd>
                    </div>
                </dl>
            </Alert>
        </Layout>
    );
};
