import { ReactElement } from 'react';
import { InformationCircleIcon } from '@heroicons/react/outline';

export const PendingChanges = ({
    simTypeText,
}: {
    simTypeText: string;
}): ReactElement => {
    return (
        <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 pt-5 sm:p-6 flex flex-col items-start">
                <div className="sm:mb-2 flex flex-row items-center">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                        <InformationCircleIcon
                            className="h-6 w-6 text-primary-600"
                            aria-hidden="true"
                        />
                    </div>
                    <div className="ml-1 text-lg leading-6 font-medium">
                        Pending Changes
                    </div>
                </div>
                <p className="px-11">
                    One or more changes are pending on this {simTypeText}.
                    Please check back later to make further modifications.
                </p>
            </div>
        </div>
    );
};
