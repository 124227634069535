import { ReactElement } from 'react';
import { InformationCircleIcon } from '@heroicons/react/outline';

import { TGenericErrorProps } from './generic-error.types';

export const GenericError = ({
    errors = [],
}: TGenericErrorProps): ReactElement => {
    const errorList = errors.filter(error => error != false);
    return (
        <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6 flex flex-col items-start">
                <div className="sm:mb-2 flex flex-row items-center">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                        <InformationCircleIcon
                            className="h-6 w-6 text-red-600"
                            aria-hidden="true"
                        />
                    </div>
                    <div className="ml-1 text-lg leading-6 font-medium">
                        Sorry, something went wrong
                    </div>
                </div>
                {errorList.length > 0 ? (
                    <div className="px-11 pt-2 flex flex-col">
                        <ul className="list-disc list-inside">
                            {errors.map((error, index) => (
                                <li key={index + 'error'}>{error}</li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    <p className="px-11 pt-2">
                        Please refresh your browser and try again, or get in
                        touch if this continues.
                    </p>
                )}
            </div>
        </div>
    );
};
