import { ChevronDownIcon } from '@heroicons/react/outline';
import Link from 'next/link';
import { useState } from 'react';

type selectOptionType = { label: string; value: string };
interface SelectTabProps {
    data: Array<selectOptionType>;
    asPath: string;
    isSelected: boolean;
}

const SelectTab: React.FC<SelectTabProps> = ({
    data,
    asPath,
    isSelected,
}: SelectTabProps): JSX.Element => {
    const [isOpen, _isOpen] = useState(false);
    const text = data.find(item => item.value == asPath)?.label;
    return (
        <div
            className="group relative"
            onMouseLeave={() => _isOpen(false)}
            onMouseEnter={() => _isOpen(true)}
        >
            <button
                className="flex flex-row w-full gap-2 my-auto justify-between items-center pb-2"
                aria-hidden="true"
            >
                <span
                    className={`text-white text-sm ${
                        isSelected ? '' : ' opacity-60 '
                    }`}
                >
                    {text || data[0].label}
                </span>
                <ChevronDownIcon className="-mr-1 h-8 w-5 text-gray-400" />
            </button>
            <div
                className={`absolute top-7 z-10 bg-transparent ${
                    isOpen ? 'block' : 'hidden'
                }`}
            >
                <ul className="bg-lightPrimary w-40 text-center flex flex-col rounded-sm overflow-hidden shadow-sm h-auto text-white">
                    {data.map(item => {
                        return (
                            <div key={item.label + 'dropdown'}>
                                <Link href={item.value}>
                                    <a className="flex justify-center text-sm py-2 px-1 m-0 border-b border-white border-opacity-20 bg-lightPrimary hover:bg-lightPrimary1">
                                        {item.label}
                                    </a>
                                </Link>
                            </div>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default SelectTab;
