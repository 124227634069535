import { Children, cloneElement, ReactElement } from 'react';
import Link from 'next/link';
import { Avatar, Select } from '@joshfarrant/tailwind-ui-react';
import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { useRouter } from 'next/router';

import { useToggle } from '../../../hooks';
import { Search } from '../..';
import { TNavBarProps, TNavBarLinkProps } from './nav-bar.types';
import { useUser } from '../../../contexts';
import SelectTab from '../select/selectTab';

const NavBarLink = ({
    children,
    href,
    isActive = false,
    isMobile = false,
    isExternal = false,
}: TNavBarLinkProps): ReactElement => {
    const linkProps: Record<string, string> = {};

    if (isExternal) {
        linkProps['href'] = href;
    }

    const link = (
        <a
            className={clsx(
                'font-medium rounded-md px-3 py-2',
                isMobile
                    ? 'block text-base text-gray-900 hover:bg-gray-100 hover:text-gray-800'
                    : [
                          'text-sm bg-white bg-opacity-0 hover:bg-opacity-10',
                          isActive ? 'text-white' : 'text-cyan-100',
                      ],
            )}
            aria-current={isActive ? 'page' : 'false'}
            {...linkProps}
        >
            {children}
        </a>
    );

    return isExternal ? link : <Link href={href}>{link}</Link>;
};

export const NavBar = ({ links, userLinks }: TNavBarProps): ReactElement => {
    const [mobileNavigationOpen, toggleMobileNavigationOpen] = useToggle(false);
    const { pathname, query, asPath } = useRouter();
    const { serviceProviders } = useUser();
    const { spid } = query;

    const simsLink = [
        {
            label: 'Your SIMs',
            value: `/${spid}/sims/allocated`,
        },
        {
            label: 'Activate SIM',
            value: `/${spid}/sims/available`,
        },
    ];

    const eSimsLink = [
        {
            label: 'Your eSIMs',
            value: `/${spid}/e-sims/allocated`,
        },
        {
            label: 'Activate eSIM',
            value: `/${spid}/e-sims/available`,
        },
    ];

    const isESim = pathname.includes('e-sims');

    return (
        <nav className="pb-28 bg-gradient-to-r from-blue-800 to-cyan-600">
            <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="relative flex flex-wrap items-center justify-center lg:justify-between">
                    <div className="hidden sm:block absolute left-0 py-5 flex-shrink-0 lg:static">
                        <Link href={`/${query.spid}/sims/allocated`}>
                            <a className="flex flex-row items-center">
                                <img
                                    className="h-8 w-auto filter brightness-0 invert"
                                    src="/logo@2x.png"
                                    alt="Apalo"
                                />
                            </a>
                        </Link>
                    </div>

                    <div className="hidden lg:ml-4 lg:flex lg:items-center lg:py-5 lg:pr-0.5">
                        {serviceProviders.length > 1 && (
                            <Select
                                className="w-60 mr-3"
                                options={serviceProviders.map(
                                    ({ spid, name }) => ({
                                        value: spid,
                                        label: `${name} [${spid}]`,
                                    }),
                                )}
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                onChange={(spid: string) => {
                                    window.location.href = `/${spid}/${
                                        isESim ? 'e-sims' : 'sims'
                                    }/allocated`;
                                }}
                                value={query.spid as string}
                                label="Service provider"
                                labelHidden
                                renderSelect={({
                                    className: _className,
                                    children,
                                    ...props
                                }) => (
                                    <select
                                        id="service-provider"
                                        name="service-provider"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-transparent text-white bg-white bg-opacity-20 focus:outline-none focus:text-gray-900 placeholder-white focus:bg-opacity-100 focus:border-transparent focus:placeholder-gray-500 focus:ring-0 sm:text-sm rounded-md"
                                        {...props}
                                    >
                                        {Children.map(children, child =>
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore
                                            cloneElement(child, {
                                                className: 'text-black',
                                            }),
                                        )}
                                    </select>
                                )}
                                renderLabel={props => (
                                    <label
                                        htmlFor="service-provider"
                                        {...props}
                                    />
                                )}
                            />
                        )}
                        <div className="ml-4 relative flex-shrink-0">
                            <Menu>
                                <Menu.Button
                                    className="bg-white rounded-full flex w-8 h-8 text-sm ring-2 ring-white ring-opacity-20 focus:outline-none focus:ring-opacity-100"
                                    id="user-menu"
                                >
                                    <span className="sr-only">
                                        Open user menu
                                    </span>
                                    <Avatar size="small" />
                                </Menu.Button>
                                <Transition
                                    className="origin-top-right z-40 absolute -right-2 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    enter=""
                                    enterFrom=""
                                    enterTo=""
                                    leave="transition ease-in duration-75"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Menu.Items>
                                        {userLinks.map(
                                            ({ name, onClick, href }) => {
                                                const content = ({
                                                    active,
                                                }: {
                                                    active: boolean;
                                                }) => {
                                                    const classes = clsx(
                                                        'block w-full text-left px-4 py-2 text-sm hover:bg-gray-100',
                                                        active
                                                            ? 'bg-gray-200 text-gray-900'
                                                            : 'text-gray-700',
                                                    );

                                                    return href ? (
                                                        <a
                                                            href={href}
                                                            className={classes}
                                                        >
                                                            {name}
                                                        </a>
                                                    ) : (
                                                        <button
                                                            onClick={onClick}
                                                            className={classes}
                                                        >
                                                            {name}
                                                        </button>
                                                    );
                                                };

                                                return (
                                                    <Menu.Item key={name}>
                                                        {content}
                                                    </Menu.Item>
                                                );
                                            },
                                        )}
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>

                    <div className="w-full py-5 lg:border-t lg:border-white lg:border-opacity-20">
                        <div className="lg:grid lg:grid-cols-3 lg:gap-8 lg:items-center">
                            <div className="hidden lg:block lg:col-span-2">
                                <nav className="flex space-x-4">
                                    <SelectTab
                                        asPath={asPath}
                                        isSelected={!asPath.includes('e-sims')}
                                        data={simsLink}
                                    />
                                    <SelectTab
                                        asPath={asPath}
                                        isSelected={asPath.includes('e-sims')}
                                        data={eSimsLink}
                                    />
                                </nav>
                            </div>
                            <div className="pr-12 sm:px-12 lg:px-0">
                                <Search />
                            </div>
                        </div>
                    </div>
                    <div className="absolute right-0 flex-shrink-0 lg:hidden">
                        <button
                            type="button"
                            className="bg-transparent p-2 rounded-md inline-flex items-center justify-center text-cyan-200 hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white"
                            aria-expanded="false"
                            onClick={toggleMobileNavigationOpen}
                        >
                            <span className="sr-only">Open main menu</span>
                            <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <div className="lg:hidden">
                <Transition
                    show={mobileNavigationOpen}
                    className="z-20 fixed inset-0 bg-black bg-opacity-25"
                    enter="transition ease-out duration-150"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                />

                <Transition
                    show={mobileNavigationOpen}
                    className="z-30 absolute top-0 inset-x-0 max-w-3xl mx-auto w-full p-2 transition transform origin-top"
                    enter="transition ease-out duration-150"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200">
                        <div className="pt-3 pb-2">
                            <div className="flex items-center justify-between px-4">
                                <div>
                                    <img
                                        className="h-8 w-auto"
                                        src="/logo@2x.png"
                                        alt="Apalo"
                                    />
                                </div>
                                <div className="-mr-2">
                                    <button
                                        type="button"
                                        className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500"
                                        onClick={toggleMobileNavigationOpen}
                                    >
                                        <span className="sr-only">
                                            Close menu
                                        </span>
                                        {/* <!-- Heroicon name: outline/x --> */}
                                        <svg
                                            className="h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>

                            <div className="mt-3 px-2 space-y-1">
                                {links.map(({ name, ...props }) => (
                                    <NavBarLink
                                        {...props}
                                        key={name}
                                        isMobile
                                        isActive={pathname === props.pathname}
                                    >
                                        {name}
                                    </NavBarLink>
                                ))}
                            </div>
                        </div>
                        {serviceProviders.length > 1 && (
                            <div className="pt-2 pb-2">
                                <div className="px-2 space-y-1">
                                    {serviceProviders.map(({ name, spid }) => (
                                        <NavBarLink
                                            href={`/${spid}/sims/allocated`}
                                            key={spid}
                                            isMobile
                                            isActive={spid === query.spid}
                                            isExternal
                                        >
                                            {name}{' '}
                                            <span className="ml-4 text-gray-400">
                                                [{spid}]
                                            </span>
                                        </NavBarLink>
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className="pt-4 pb-2">
                            <div className="px-2 space-y-1">
                                {userLinks.map(({ name, onClick, href }) => {
                                    const classes =
                                        'block w-full text-left rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800';

                                    return href ? (
                                        <a
                                            key={name}
                                            href={href}
                                            className={classes}
                                        >
                                            {name}
                                        </a>
                                    ) : (
                                        <button
                                            key={name}
                                            onClick={onClick}
                                            className={classes}
                                        >
                                            {name}
                                        </button>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </nav>
    );
};
