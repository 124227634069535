import { ReactElement, useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Badge, Toggle } from '@joshfarrant/tailwind-ui-react';
import { useQuery } from 'react-query';
import clsx from 'clsx';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { Switch } from '@headlessui/react';
import {
    GenericError,
    Layout,
    NoSimDataFound,
    Table,
    TableHeader,
    TTableColumn,
} from '../..';
import { listAllocatedSims, listPendingOperations } from '../../../api';
import {
    formatPaginationParams,
    getStatusBadgeColor,
    isErrorResponse,
} from '../../../utils';
import {
    TAllocatedSim,
    TApiListResponse,
    TAvailableSim,
    TPendingOperation,
    TSimStatus,
    TSuccessApiListResponse,
} from '../../../types';
import { useApp, useUser } from '../../../contexts';
import { checkRequiredParams } from '../../../utils/checkRequiredParams';
import { simTypes } from 'src/constants';
import QRAlert from '../available-sims/QRCode';

type TTableData = Omit<TAllocatedSim, 'enterprise'>;

const canSort = true;

const headerWithTitle = (title: string) => (props: unknown) => (
    <TableHeader title={title} canSort={canSort} {...props} />
);

type propType = { simType: string };

export const AllocatedSims = ({ simType }: propType): ReactElement => {
    const router = useRouter();
    const [pageIndex, setPageIndex] = useState(1);
    const [sortIsDesc, setSortIsDesc] = useState(false);
    const [activatingSimIccid, setActivatingSimIccid] = useState<string>('');
    const [pageSize] = useState(10);
    const [sortByColumn, setSortByColumn] = useState<string>('iccid');
    const { showAllSims, setShowAllSims } = useApp();
    const [qrcode, _qrcode] = useState('');
    const { auth } = useUser();
    const { spid } = router.query;
    const activateCancelButtonRef = useRef(null);

    const resetSearch = () => {
        setPageIndex(1);
        setSortByColumn('iccid');
        setSortIsDesc(false);
    };
    const simTitle = simType == simTypes.eSims ? 'eSIM' : 'SIM';
    const heading = `Your ${simTitle}s`;
    const pageTitle = `Allocated ${simTitle}s : ApaloSIM`;

    const listAllocatedSimsQuery = useQuery<
        TApiListResponse<TAllocatedSim>,
        string[]
    >(
        [
            'allocatedSims',
            { spid, sortByColumn, sortIsDesc, showAllSims },
            pageIndex,
        ],
        async () => {
            checkRequiredParams(spid);
            let params: Record<
                string,
                string | number
            > = formatPaginationParams({
                pageIndex,
                pageSize,
            });

            params = {
                ...params,
                sort: `${sortIsDesc ? '-' : '+'}${sortByColumn}`,
            };

            if (!showAllSims) {
                params = {
                    ...params,
                    type: 'sip',
                };
            }

            const res = await listAllocatedSims(
                auth.header,
                spid as string,
                simType,
                params,
            );

            if (isErrorResponse(res)) {
                throw res.errors;
            }

            return res;
        },
        { keepPreviousData: true },
    );

    const listPendingOperationsQuery = useQuery<
        TApiListResponse<TPendingOperation>,
        string[]
    >(
        ['pendingOperations', { spid }],
        async () => {
            checkRequiredParams(spid);
            const res = await listPendingOperations(
                auth.header,
                spid as string,
                simType,
            );

            if (isErrorResponse(res)) {
                throw res.errors;
            }

            // Sometimes the API returns [null] for the data property on this request
            if (res.data.length === 1 && res.data[0] === null) {
                return {
                    ...res,
                    data: [],
                };
            }

            return res;
        },
        { keepPreviousData: true },
    );

    const { data: allocatedSimsResponse } = listAllocatedSimsQuery;
    const { data: pendingOperationsResponse } = listPendingOperationsQuery;

    let errors: string[] = [];

    if (listAllocatedSimsQuery.isError) {
        errors = [...errors, ...listAllocatedSimsQuery.error];
    }

    if (listPendingOperationsQuery.isError) {
        errors = [...errors, ...listPendingOperationsQuery.error];
    }

    if (errors.length > 0) {
        return (
            <Layout pageTitle={pageTitle} heading={heading}>
                <GenericError errors={errors} />
            </Layout>
        );
    }

    if (!allocatedSimsResponse) {
        return <Layout pageTitle={pageTitle} heading={heading} isLoading />;
    }

    if (!allocatedSimsResponse.success) {
        return (
            <Layout pageTitle={pageTitle} heading={heading}>
                <GenericError />
            </Layout>
        );
    }

    const onSort = (columnId: string, isDesc: boolean): void => {
        setSortByColumn(columnId);
        setSortIsDesc(isDesc);
    };

    const {
        data: tableData,
        count: itemCount,
    } = allocatedSimsResponse as TSuccessApiListResponse<TAllocatedSim>;

    if (showAllSims && itemCount === 0) {
        return (
            <NoSimDataFound
                spid={spid as string}
                pageTitle={pageTitle}
                heading={heading}
                simTitle={simTitle}
                simType={simType}
                status="active"
            />
        );
    }

    const pendingSims = (pendingOperationsResponse as TSuccessApiListResponse<TPendingOperation>)?.data.filter(
        operation => operation.action === 'activateSim',
    );

    const BaseCell = ({
        row,
        value,
    }: {
        row: { values: { type: string } };
        value: TSimStatus;
    }) => (
        <span className={clsx(row.values.type !== 'sip' && 'text-gray-400')}>
            {value}
        </span>
    );

    const tableColumns: TTableColumn<TTableData>[] = [
        {
            Header: headerWithTitle('ICCID'),
            accessor: 'iccid',
            Cell: BaseCell,
        },
        {
            Header: headerWithTitle('MSISDN'),
            accessor: 'msisdn',
            Cell: BaseCell,
        },
        {
            Header: headerWithTitle('Status'),

            accessor: 'status',
            Cell: ({
                row,
                value,
            }: {
                row: { values: { type: string } };
                value: TSimStatus;
            }) => (
                <Badge
                    color={
                        row.values.type === 'sip'
                            ? getStatusBadgeColor(value)
                            : 'gray'
                    }
                    shape="rounded"
                >
                    {value.toUpperCase().replaceAll('_', ' ')}
                </Badge>
            ),
        },
        {
            Header: headerWithTitle('Label'),
            accessor: 'label',
            disableSortBy: true,
            Cell: BaseCell,
        },
        {
            Header: headerWithTitle('Type'),
            accessor: 'type',
            Cell: BaseCell,
        },
        {
            Header: () => (
                <div className="flex flex-col lg:flex-row items-center pr-2 py-3 xl:pl-6 xl:pr-0">
                    <Switch.Group>
                        <Switch.Label className="mb-1 lg:mb-0 lg:mr-2 text-xs font-medium text-gray-500">
                            Show non-SIP {simTitle}s
                        </Switch.Label>
                        <Toggle
                            srLabel={
                                showAllSims
                                    ? `Only show SIP ${simTitle}s`
                                    : `Show non-SIP ${simTitle}s`
                            }
                            enabled={showAllSims}
                            onChange={checked => {
                                setShowAllSims(checked);
                                resetSearch();
                            }}
                            disabled={listAllocatedSimsQuery.isLoading}
                        />
                    </Switch.Group>
                </div>
            ),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            accessor: 'editUrl',
            disableSortBy: true,
            Cell: ({
                row,
            }: {
                row: {
                    values: { iccid: string; type: string; qr_code: string };
                    original: TAvailableSim;
                };
            }) => {
                return (
                    <>
                        {row.values.type == 'sip' ? (
                            <Link
                                href={`/${spid}/${
                                    simType == simTypes.eSims
                                        ? 'e-sims'
                                        : 'sims'
                                }/allocated/${row.values.iccid}/mobile`}
                            >
                                <a className="text-primary-600 hover:text-primary-900">
                                    Edit
                                </a>
                            </Link>
                        ) : null}
                        {simType == simTypes.eSims ? (
                            <button
                                onClick={() => {
                                    setActivatingSimIccid(row.values.iccid);
                                    _qrcode(row.original.qr_code);
                                }}
                                className="text-primary-600 hover:text-primary-900 ml-2"
                            >
                                QR code
                            </button>
                        ) : null}
                    </>
                );
            },
        },
    ];

    return (
        <Layout pageTitle={pageTitle} heading={heading}>
            {pendingSims?.length > 0 && (
                <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:p-6 flex flex-col items-start">
                        <div className="sm:mb-2 flex flex-row items-center">
                            apiBase
                            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                                <InformationCircleIcon
                                    className="h-6 w-6 text-primary-600"
                                    aria-hidden="true"
                                />
                            </div>
                            <div className="ml-1 text-lg leading-6 font-medium">
                                You have {pendingSims.length} pending{' '}
                                {pendingSims.length === 1
                                    ? simTitle
                                    : simTitle + 's'}
                            </div>
                        </div>
                        <dl className="px-12 flex flex-col">
                            {pendingSims.map(pendingSim => (
                                <div
                                    key={pendingSim.txn}
                                    className="text-sm font-medium text-gray-500 px-4 py-3 sm:py-2 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0"
                                >
                                    <dt className="col-span-1">
                                        {pendingSim.iccid}
                                    </dt>
                                    <dd className="mt-1 sm:mt-0 col-span-1 text-gray-400">
                                        {pendingSim.msisdn}
                                    </dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            )}
            <Table<TTableData>
                data={tableData}
                columns={tableColumns}
                initialState={{
                    sortBy: [{ id: 'iccid', desc: false }],
                    pageIndex,
                }}
                itemCount={itemCount}
                pageSize={pageSize}
                onPaginate={setPageIndex}
                onSort={onSort}
                canSort={canSort}
            />
            {simType == simTypes.eSims && activatingSimIccid != '' ? (
                <QRAlert
                    activateCancelButtonRef={activateCancelButtonRef}
                    setActivatingSimIccid={setActivatingSimIccid}
                    activatingSimIccid={activatingSimIccid}
                    QRCode={qrcode}
                />
            ) : null}
        </Layout>
    );
};
