import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { PrimaryButton, WhiteButton } from 'src/components/atoms';
import { Alert } from 'src/components/molecules';

interface MyComponentProps {
    QRCode: string;
    activatingSimIccid: string;
    setActivatingSimIccid: Dispatch<SetStateAction<string>>;
    activateCancelButtonRef: MutableRefObject<null>;
}

const QRAlert = ({
    activateCancelButtonRef,
    setActivatingSimIccid,
    activatingSimIccid,
    QRCode,
}: MyComponentProps): JSX.Element => {
    const handleDownload = async () => {
        const link = document.createElement('a');
        link.href = QRCode;
        link.download = 'image.png'; // Specify the filename
        link.click();
    };

    return (
        <Alert
            heading="Activate eSIM?"
            onClose={() => {}}
            show={Boolean(activatingSimIccid)}
            initialFocus={activateCancelButtonRef}
            Buttons={() => (
                <div className="flex justify-end">
                    <WhiteButton
                        className="mr-4"
                        onClick={() => {
                            setActivatingSimIccid('');
                        }}
                        ref={activateCancelButtonRef}
                    >
                        Cancel
                    </WhiteButton>
                    {QRCode ? (
                        <PrimaryButton
                            onClick={handleDownload}
                            disabled={false}
                        >
                            Download
                        </PrimaryButton>
                    ) : null}
                </div>
            )}
            Icon={() => (
                <ExclamationCircleIcon
                    className="h-6 w-6 text-primary-600"
                    aria-hidden="true"
                />
            )}
            iconBackgroundClassName="bg-primary-100"
        >
            <p className="text-sm text-gray-500">
                Are you sure you want to activate the following eSIM?
            </p>
            <dl className="mt-5 mx-5">
                {QRCode ? <img alt="qr" src={QRCode} /> : null}
                {!QRCode ? <span> No QR code found </span> : <></>}
            </dl>
        </Alert>
    );
};
export default QRAlert;
