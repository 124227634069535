import { InformationCircleIcon } from '@heroicons/react/outline';
import Link from 'next/link';
import React, { ReactElement } from 'react';
import { Layout } from 'src/components/templates';
import { simTypes } from 'src/constants';

type NoSimProps = {
    pageTitle: string;
    heading: string;
    simTitle: string;
    spid: string;
    simType: string;
    status: string;
};
export const NoSimDataFound = ({
    pageTitle,
    heading,
    simTitle,
    spid,
    simType,
    status,
}: NoSimProps): ReactElement => {
    return (
        <Layout pageTitle={pageTitle} heading={heading}>
            <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:p-6 flex flex-col items-start">
                    <div className="sm:mb-2 flex flex-row items-center">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                            <InformationCircleIcon
                                className="h-6 w-6 text-primary-600"
                                aria-hidden="true"
                            />
                        </div>
                        <div className="ml-1 text-lg leading-6 font-medium">
                            You currently have no {status} {simTitle}s
                        </div>
                    </div>
                    {status == 'active' ? (
                        <p className="px-11 pt-2">
                            Please{' '}
                            <Link
                                href={`/${spid}/${
                                    simType == simTypes.eSims
                                        ? 'e-sims'
                                        : 'sims'
                                }/available`}
                            >
                                <a className="text-primary-600 hover:text-primary-900 underline">
                                    activate a {simTitle}
                                </a>
                            </Link>{' '}
                            to get started.
                        </p>
                    ) : null}
                </div>
            </div>
        </Layout>
    );
};
